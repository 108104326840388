import { mapState } from 'vuex'
import CrudTable from '@/lib/uncrudtable/components/CrudTable'
import { BASE_URL } from '@/variables'

export default {
  name: 'PendingDownloadListTable',
  components: {
    CrudTable
  },
  data () {
    return {
      storeModule: 'pendingDownloads'
    }
  },
  computed: {
    ...mapState({
      crudTableConfig: function (state) { return state[this.storeModule].crudTableConfig },
      loading: function (state) { return state[this.storeModule].loading },
      downloadedData: function (state) { return state[this.storeModule].downloadedData }
    })

  },
  methods: {
    getFilename (item) {
      return item.file_endpoint.split('/').pop()
    },

    btn_props (item) {
      return {
        download: this.getFilename(item),
        icon: true,
        disabled: !item.can_download_file,
        href: `${BASE_URL}${item.file_endpoint}`
      }
    },

    icon_props (item) {
      let klass = 'fas'
      switch (item.status) {
        case 'Errored':
          klass = `${klass} fa-ban`
          break
        case 'Generated':
          klass = `${klass} fa-download`
          break
        default:
          klass = `${klass} fa-spinner`
      }

      return {
        color: 'primary',
        class: klass
      }
    }
  }
}
